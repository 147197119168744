@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'DM_Sans_regular';
    src: url('./font/DM_Sans/DMSans-Regular.ttf');
  }  

body {
    font-family: 'DM_Sans_regular', sans-serif;
    /* font-family: 'Inter'; */
    /* font-size: 22px; */
}
