.mail {
  height: 100%;
  background: #ecf3ff;
}

.mail_search {
  box-sizing: border-box;
  height: 48px;
  display: flex;
  padding-left: 30px;
  margin-top: 60px;
  margin-bottom: 30px;

  background: #dfe9fb;
  border-radius: 10px;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .mail_search {
    height: 40px;
    display: flex;
    padding-left: 25px;
    border-radius: 7px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .mail_search {
    height: 32px;
    display: flex;
    padding-left: 25px;
    border-radius: 8px;
  }
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


/* ::-webkit-scrollbar {
  width: 25px;
}

::-webkit-scrollbar-thumb {
  background-color: #7a7d89;
}

::-webkit-scrollbar-button:single-button{
  background: lightgray; */
/* } */