.sidebar {
  position: absolute;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: #011a59;
}

.sidebar_line {
  width: 263px;
  height: 0px;
  left: 37px;
  border: 0.5px solid #7b9cf2;
}

.sidebar_menu {
  color: #ffffff;
}

.sidebar_text {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #ffffff;
}

.sidebar_admin {
  background: linear-gradient(
    122.78deg,
    #1e1e1e 15.18%,
    rgba(30, 30, 30, 0) 118.98%
  );
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .sidebar_line {
    width: 210px;
  }
  .sidebar_text {
    font-size: 23px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .sidebar_text {
    font-size: 21px;
  }
  .sidebar_line {
    width: 190px;
  }
}
