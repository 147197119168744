code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  
  h4 {
    text-align: left;
    margin-left: 40px;
  }
  
  hr {
    border-top: 0.5px solid #c8c8c8;
  }
  
  #drawArea {
    overflow: hidden;
    /* width: 612px; */
    /* height: 792px; */
    /* border: 1px solid black; */
    /*PROBLEMATIC!!!!*/
    /* margin-left: 30%; */
    position: relative;
  }
  
  .crosshair {
    cursor: crosshair;
  }
  
  .drawing {
    z-index: 1;
    position: absolute !important;
    top: 0;
    left: 0;
  }
  
  .path {
    fill: none;
    stroke-width: 1px;
    stroke: red;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
  
  .drawing {
    width: 100%;
    height: 100%;
  }