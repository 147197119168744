canvas, .canvas-container {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
 
.toolbar {
    width: 100%;
    background-color: rgb(50, 54, 57);
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
 
#pdf-container {
    margin-top: 60px;
    padding-left: 10px;
    text-align: center;
}
 
button:focus {
    outline: 0;
}
 
.toolbar .tool {
    display: inline-block;
    color: #fff;
    height: 100%;
    padding-top: 10px;
    padding-left: 10px;
    margin-right: 5px;
}
 
.toolbar .tool label,
.toolbar .tool select,
.toolbar .tool input {
    display: inline-block;
    width: auto;
    height: auto !important;
    padding: 0;
 
}
 
.toolbar .tool input {
    width: 50px;
}
 
.toolbar .tool .color-tool {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    border: 0;
    cursor: pointer;
    display: inline-block;
}
 
.toolbar .tool .color-tool.active {
    -webkit-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
}
 
.toolbar .tool:nth-last-child(1) {
    float: right;
}
 
.toolbar .tool .tool-button {
    background-color: rgb(50, 54, 57);
    border: 1px solid rgb(50, 54, 57);
    color: #fff;
    cursor: pointer;
}
 
.toolbar .tool .tool-button:hover,
.toolbar .tool .tool-button.active {
    background-color: rgb(82, 86, 89);
    border-color: rgb(82, 86, 89);
}
 

canvas, .canvas-container {
    margin-bottom: 25px;
}
 
.canvas-container {
    margin-left: auto;
    margin-right: auto;
}

.active path {
    stroke: #03298C; /* set the active stroke color */
  }
  
.inactive path {
    stroke: #C8C8C8; /* set the inactive stroke color */
}