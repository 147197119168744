.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.my-draggable {
  cursor: pointer;
  width: 200px;
  height: 200px;
  background-color: #cca;
}

#mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  text-align: center;
  border: 1px solid #d3d3d3;
}

#mydivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196f3;
  color: #fff;
}

.react-draggable,
.cursor {
  cursor: move;
}

.no-cursor {
  cursor: auto;
}

.cursor-y {
  cursor: ns-resize;
}

.cursor-x {
  cursor: ew-resize;
}

.react-draggable strong {
  background: #ddd;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  margin-bottom: 10px;
  padding: 3px 5px;
  text-align: center;
}

.box {
  background: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 180px;
  height: 180px;
  margin: 10px;
  padding: 10px;
  float: left;
}

.scroll-width::-webkit-scrollbar {
  width: 8px;
}

.rem-position-fix {
  position: static !important;
}

/* @media (-webkit-min-device-pixel-ratio: 1) {
  body {
    background-color: lightgreen;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.25) {
  body {
    background: gold;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.5) {
  body {
    background-color: lightcoral;
  }
} */

.gradient-border {
  border: 2px solid transparent;
  border-image-slice: 1;
  animation: animatedgradient 3s ease alternate infinite;
  transition: border 0.3s ease, border-radius 0.3s ease;
}

@keyframes animatedgradient {
  0% {
    -webkit-box-shadow: 0 0 17px 3px #FE8585, 0 0 4px 2px #FE8585;
    box-shadow: 0 0 17px 3px #FE8585, 0 0 4px 2px #FE8585;
  }

  25% {
    -webkit-box-shadow: 0 0 17px 3px white, 0 0 4px 2px white;
    box-shadow: 0 0 17px 3px white, 0 0 4px 2px white;
  }

  50% {
    -webkit-box-shadow: 0 0 17px 3px #FE8585, 0 0 4px 2px #FE8585;
    box-shadow: 0 0 17px 3px #FE8585, 0 0 4px 2px #FE8585;
  }

  75% {
    -webkit-box-shadow: 0 0 17px 3px white, 0 0 4px 2px white;
    box-shadow: 0 0 17px 3px white, 0 0 4px 2px white;
  }

  100% {
    -webkit-box-shadow: 0 0 17px 3px #FE8585, 0 0 4px 2px #FE8585;
    box-shadow: 0 0 17px 3px #FE8585, 0 0 4px 2px #FE8585;
  }
}


.badgeClass>span>span {
  /* text-align: center; */
  max-width: 70px;
  white-space: nowrap;
}