.step-item {
  @apply relative flex flex-col justify-center items-center w-full;
}
.step-item:not(:first-child):before {
  @apply content-[''] bg-[#D9D9D9] absolute w-[70%] h-[1px] right-[65%] top-1/2 -translate-y-2/4;
}
.step {
  @apply w-12 h-12 res125:w-9 res125:h-9 flex items-center justify-center z-10 relative  text-[#D9D9D9] rounded-full font-semibold border;
}
.active .step {
  @apply bg-[#03298C];
}
.complete .step {
  @apply bg-[#03298C];
}
.complete p {
  @apply text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-[#03298C];
}

.chip {
  text-wrap: wrap;
  background-color: #03298c !important;
  color: white !important;
  @apply res150:text-[9px] res150:leading-[8px] !important;
}

.highlightOption,
.selected {
  background-color: white !important;
  color: black !important;
}
.multiSelectContainer li:hover {
  background-color: #ecf3ff !important;
  color: black !important;
}

.multiSelectContainer li {
  /* background-color: white !important; */
  color: black;
}

.searchBox {
  /* font-size: 14px;
  color: #929292;
  width: 298px; */
  @apply res150:text-[10px] text-[#929292] w-[298px] text-sm res150:w-[180px] res125:w-[200px];
}

.checkbox {
  border: 1px solid #000 !important;
  /* background-color: white !important; */
}

.numberbox::-webkit-outer-spin-button,
.numberbox::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkbox:checked {
  background-color: #929292;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
}
@media (-webkit-min-device-pixel-ratio: 1.5) {
  .step {
    @apply w-6 h-6 text-xs;
  }
}
