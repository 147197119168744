/* width */
.scrolling::-webkit-scrollbar,
.scrolling-add-comment::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.scrolling::-webkit-scrollbar-track,
.scrolling-add-comment::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.scrolling::-webkit-scrollbar-thumb {
    background: #03298C;
}

.scrolling-add-comment::-webkit-scrollbar-thumb {
    background-color: #DEE2E6;
}

.add-comment:focus {
    border: 0px !important;
    box-shadow: none !important;
}